import React from 'react';
import styled from '@emotion/styled';

import { ThemeWrapper } from 'styles';
import useSitedataAssets from 'hooks/useSitedataAssets';

const AuthLayout = ({ children }) => {
  const { logoWhite } = useSitedataAssets();
  return (
    <ThemeWrapper>
      <StyledAuthLayout>
        <div className="bar">
          {logoWhite && <img src={logoWhite} alt="logo" className="logo" />}
        </div>
        <div className="content">{children}</div>
      </StyledAuthLayout>
    </ThemeWrapper>
  );
};

export default AuthLayout;

const StyledAuthLayout = styled.div`
  /* mobile first */
  display: grid;
  grid-template-rows: 70px auto;

  .bar {
    background: black;
    padding: 26px 15px;

    .logo {
      color: #fff;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    grid-template-rows: none;
    grid-template-columns: 300px 1fr;

    .bar {
      min-height: 100vh;
    }

    .content {
      padding: 80px;
      align-items: center;
      justify-content: center;
    }
  }
`;
