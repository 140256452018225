import { fetch } from 'utils/fetch';
import keysToCamel from 'utils/keysToCamel';
import { urlEncodeBody } from 'utils/requests';

export type RegisterPayload = {
  brokerMlsId: string;
  password: string;
  name: string;
  phone?: string;
  phoneOffice?: string;
  commissionPercentage?: number;
  commissionFixedFee?: number;
  templateId?: number;
};

export async function register(payload: RegisterPayload, inviteCode: string): Promise<LoginData> {
  const response = await fetch(`/users/register`, {
    method: 'POST',
    body: urlEncodeBody({
      broker_mls_id: payload.brokerMlsId,
      password: payload.password,
      name: payload.name,
      phone: payload.phone,
      office_phone: payload.phoneOffice,
      commission_percentage: payload.commissionPercentage,
      commission_fixed_fee: payload.commissionFixedFee,
      invite_code: inviteCode
    })
  });

  const data = await response.json();

  return keysToCamel(data);
}

export async function checkInviteCode(
  inviteCode: string
): Promise<{ email: string; name: string; phone: string }> {
  const response = await fetch(`/register?invite_code=${inviteCode}`);

  const data = await response.json();

  return data;
}
