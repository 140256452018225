import React, { useEffect } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import AuthLayout from 'components/AuthLayout';
import RegisterPage from 'views/RegisterPage';

const RegisterUser = () => {
  const { search } = useLocation();
  const inviteCode = new URLSearchParams(search).get('invite_code');

  useEffect(() => {
    if (!inviteCode) navigate('/');
  }, [inviteCode]);

  if (!inviteCode) return null;

  return (
    <AuthLayout>
      <RegisterPage inviteCode={inviteCode} />
    </AuthLayout>
  );
};

export default RegisterUser;
